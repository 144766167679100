import { useState } from "react";
import Button from "../../Components/Button/Button";
import classes from "./LegalNoticeModalBody.module.css";

type LegalNoticeModalBodyProps = {
  cancel: () => void;
  confirm: () => void;
  loading: boolean;
};

const LegalNoticeModalBody = ({
  cancel,
  confirm,
  loading,
}: LegalNoticeModalBodyProps) => {
  const legalNotice = [
    "By choosing the in-app option or clicking the link to delete your personal data, FCMB shall enable Customer (You) to delete personal data using the functionality provided by the Application. For certain deletions, a recovery is offered by FCMB to enable recovery from accidental deletions for up to 30 days. However, this may be overridden by FCMB.",
    "After any recovery period, FCMB will permanently delete the personal data from the Application within 30 days and all existing backups within 90 days thereafter unless applicable data protection laws or legislations requires storage of the personal data.",
    " A Customer may upon a written request, apply for a certification of deletion of personal data.",
  ];

  // States
  const [agreed, setIsAgreed] = useState(false);

  return (
    <div className={classes.container}>
      <h4>Terms & Conditions</h4>
      {legalNotice.map((data, key) => {
        return <p key={key}>{data}</p>;
      })}

      <div className={classes.legalConfirm}>
        <input
          type="checkbox"
          name=""
          id="legal"
          checked={agreed}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setIsAgreed(e.target.checked);
            console.log(e.target.checked);
          }}
        />
        <label htmlFor="legal">I accept the terms and conditions</label>
      </div>

      <div className={classes.buttonSection}>
        <Button
          type="secondary"
          onClick={() => {
            cancel();
          }}
        >
          Decline
        </Button>
        <Button
          onClick={() => {
            confirm();
          }}
          loading={loading}
          disabled={!agreed}
        >
          Accept
        </Button>
      </div>
    </div>
  );
};

export default LegalNoticeModalBody;
