export const formatTime = (timeInSeconds: number) => {
  if (timeInSeconds) {
    const seconds = timeInSeconds % 60;
    const minutes = Math.floor(timeInSeconds / 60);

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    return { minutes: formattedMinutes, seconds: formattedSeconds };
  }
};
