import { Dispatch, SetStateAction, useEffect, useState } from "react";
import Button from "../../Components/Button/Button";
import Input from "../../Components/Input/Input";
import { otpType } from "../../Utilities/types";
import classes from "./EnterOtpModalBody.module.css";
import { inputChangeHandler } from "../../HelperFunctions/inputChangeHandler";
import { formatTime } from "../../Utilities/timeInMinutes";
import Error from "../../Components/Error/Error";

type EnterOtpModalBodyProps = {
  otp: otpType;
  setOtp: Dispatch<SetStateAction<otpType>>;
  onClick: () => void;
  sendingOtpRequest: boolean;
  otpError: string;
  countdownTimer: number;
  setCounddownTimer: Dispatch<SetStateAction<number>>;
  email: string;
};

const EnterOtpModalBody = ({
  otp,
  setOtp,
  onClick,
  sendingOtpRequest,
  otpError,
  countdownTimer,
  setCounddownTimer,
  email,
}: EnterOtpModalBodyProps) => {
  // State
  const [otpSuccess, setOtpSuccess] = useState("");

  // Util
  const otpSeconds = 60;

  // Effects
  useEffect(() => {
    setCounddownTimer(otpSeconds);
    setOtp({ otp: "" });

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let timer: any = null;
    if (countdownTimer > 0) {
      timer = setInterval(() => {
        setCounddownTimer((prevState) => {
          return prevState - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(timer);
    };
  });

  useEffect(() => {
    if (otpSuccess) {
      setCounddownTimer(otpSeconds);
      setTimeout(() => {
        setOtpSuccess("");
      }, 5000);
    }

    // eslint-disable-next-line
  }, [otpSuccess]);

  console.log(otpError);

  return (
    <div className={classes.container}>
      {otpSuccess && <Error type="success">{otpSuccess}</Error>}
      <h4>Enter OTP</h4>
      <p>
        An OTP has been sent to your email and phone number, enter code below to
        proceed{" "}
      </p>
      <Input
        label="Enter OTP"
        placeholder="Enter code"
        value={otp.otp}
        onChange={(e) => {
          inputChangeHandler(e, setOtp);
        }}
        name="otp"
        errorMessage={otpError}
        inValidCondition={Boolean(otpError)}
        tip={
          countdownTimer > 0 ? (
            <>
              Code expires in{" "}
              <span>
                {formatTime(countdownTimer)?.minutes}:
                {formatTime(countdownTimer)?.seconds}
              </span>
            </>
          ) : null
        }
        actionText="Resend OTP"
        action={() => {
          setOtpSuccess(`A new OTP has been sent to ${email || "your email"}`);
        }}
        actionTextCondition={countdownTimer < 40 || Boolean(otpError)}
      />

      <Button
        disabled={!otp.otp || otp?.otp.length !== 6}
        onClick={onClick}
        loading={sendingOtpRequest}
      >
        Proceed
      </Button>
    </div>
  );
};

export default EnterOtpModalBody;
